/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:030794ba-ec05-4dc1-aba5-8227549b21ed",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aAdwJbLU0",
    "aws_user_pools_web_client_id": "3hf3al0a2k84f5dbeqqa3n1vml",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3jrheu42avdlln6u3tnzvnho3u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
